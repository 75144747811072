import hero from "/assets/images/hero.jpeg";

export const heroImg = hero;

import hmv from "/assets/videos/grammy2.mp4";
import splitVid from "/assets/videos/grammy_split.mp4";
// import woodyVid from "/assets/videos/highlight-first.mp4";
import woodyVid from "/assets/videos/grammy_woody.mp4";
import brassVid from "/assets/videos/grammy_brass_light.mp4";
import actionVid from "/assets/videos/grammy_action.mp4";
import exploremv from "/assets/videos/explore.mp4";
import framemv from "/assets/videos/frame.mp4";

import search from "/assets/images/search.svg";
import bag from "/assets/images/bag.svg";
import watch from "/assets/images/watch.svg";
import right from "/assets/images/right.svg";
import replay from "/assets/images/replay.svg";
import play from "/assets/images/play.svg";
import pause from "/assets/images/pause.svg";

import yellow from "/assets/images/yellow.jpg";
import blue from "/assets/images/blue.jpg";
import white from "/assets/images/white.jpg";
import black from "/assets/images/black.jpg";
import explore1 from "/assets/images/explore1.jpg";
import explore2 from "/assets/images/explore2.jpg";
import chip from "/assets/images/chip.jpeg";
import frame from "/assets/images/frame.png";

export const heroVideo = hmv;

export const split = splitVid;
export const woody = woodyVid;
export const brasslight = brassVid;
export const action = actionVid;
export const exploreVideo = exploremv;
export const frameVideo = framemv;

export const searchImg = search;
export const bagImg = bag;
export const watchImg = watch;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;

export const yellowImg = yellow;
export const blueImg = blue;
export const whiteImg = white;
export const blackImg = black;
export const explore1Img = explore1;
export const explore2Img = explore2;
export const chipImg = chip;
export const frameImg = frame;