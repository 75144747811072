import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { heroVideo } from '../utils';
import { useEffect, useState } from 'react';

const Hero = () => {
  const [videoSrc, setVideoSrc] = useState(heroVideo)

  const handleVideoSrcSet = () => {
      setVideoSrc(heroVideo)
  }

  useEffect(() => {
    window.addEventListener('resize', handleVideoSrcSet);

    return () => {
      window.removeEventListener('reisze', handleVideoSrcSet)
    }
  }, [])

  useGSAP(() => {
    gsap.to('#hero', { opacity: 1, delay: 2 })
    gsap.to('#cta', { opacity: 1, y: -50, delay: 2 })
  }, [])

  return (
    <section className="w-full nav-height bg-black relative">
      <div className="h-5/6 w-full flex items-center justify-center flex-col">
        <p id="hero" className="text-center font-semibold text-3xl text-gray-100 opacity-0 max-md:mb-10 ">Grammy 2XR</p>
        <div className="w-5/6 pt-4">
          <video className="pointer-events-none max-h-[600px] m-auto" autoPlay muted playsInline={true} key={videoSrc}>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
      </div>

      <div
        id="cta"
        className="flex flex-col items-center opacity-0 translate-y-20"
      >
        <a href="#highlights" className="btn">Buy</a>
        <p className="font-normal text-xl">From $199/month or $1999</p>
      </div>
    </section>
  )
}

export default Hero