import {
  blackImg,
  blueImg,
  split,
  action,
  woody,
  brasslight,
  whiteImg,
  yellowImg,
} from "../utils";

export const navLists = ["Store", "Configure", "Records", "Support"];

export const highSlides = [
  {
    id: 1,
    textLists: [
      "Enter the 2XR. Groundbreaking performance.",
    ],
    video: split,
    videoDuration: 2
  },
  {
    id: 2,
    textLists: ["Wood and Brass. So strong. So heavy. So Reliable."],
    video: woody,
    videoDuration: 3,
  },
  {
    id: 3,
    textLists: [
      "Grammy 2XR has the widest sound horn in it's class.",
    ],
    video: brasslight,
    videoDuration: 3,
  },
  {
    id: 4,
    textLists: ["All-new spring loaded Action crank. Let the party begin."],
    video: action,
    videoDuration: 3,
  },
];

export const models = [
  {
    id: 1,
    title: "iPhone 15 Pro in Natural Titanium",
    color: ["#8F8A81", "#ffe7b9", "#6f6c64"],
    img: yellowImg,
  },
  {
    id: 2,
    title: "iPhone 15 Pro in Blue Titanium",
    color: ["#53596E", "#6395ff", "#21242e"],
    img: blueImg,
  },
  {
    id: 3,
    title: "iPhone 15 Pro in White Titanium",
    color: ["#C9C8C2", "#ffffff", "#C9C8C2"],
    img: whiteImg,
  },
  {
    id: 4,
    title: "iPhone 15 Pro in Black Titanium",
    color: ["#454749", "#3b3b3b", "#181819"],
    img: blackImg,
  },
];

export const sizes = [
  { label: '6.1"', value: "small" },
  { label: '6.7"', value: "large" },
];

export const footerLinks = [
  "Privacy Policy",
  "Terms of Use",
  "Sales Policy",
  "Legal",
  "Site Map",
];