import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('models/gramophone.glb')
  const { actions } = useAnimations(animations, group)

  // console.log('X', animations, actions)

  useEffect(() => {
    actions["turntable"].play();
    // actions["handle"].play();
    return () => {
      actions["turntable"].reset();
      // actions["handle"].reset();
    };
  }, []);


  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Sketchfab_model">
          <group name="875a449d5b39405780c26a1cae2f256cfbx">
            <group name="RootNode" />
          </group>
        </group>
        <group name="Object_5">
          <mesh name="Gromofon__0" geometry={nodes.Gromofon__0.geometry} material={materials['Scene_-_Root']} />
          <mesh name="Gromofon__0001" geometry={nodes.Gromofon__0001.geometry} material={materials['Scene_-_Root']} />
          <mesh name="Gromofon__0002" geometry={nodes.Gromofon__0002.geometry} material={materials['Scene_-_Root']} />
          <mesh name="Gromofon__0003" geometry={nodes.Gromofon__0003.geometry} material={materials['Scene_-_Root']} />
          <mesh name="Gromofon__0005" geometry={nodes.Gromofon__0005.geometry} material={materials['Scene_-_Root']} />
        </group>
        <group name="Object_8">
          <mesh name="Object001__0" geometry={nodes.Object001__0.geometry} material={materials['Scene_-_Root']} position={[0.437, 0.565, 1.488]} />
          {/* <mesh name="Object001__0" geometry={nodes.Object001__0.geometry} material={materials['Scene_-_Root']} position={[0.437, 0.565, 1.488]} /> */}
        </group>
        <group name="Object_11">
          <mesh name="Object002__0" geometry={nodes.Object002__0.geometry} material={materials['Scene_-_Root']} position={[1.008, 0.446, 1.577]} />
          {/* <mesh name="Object002__0" geometry={nodes.Object002__0.geometry} material={materials['Scene_-_Root']} position={[1.008, 0.446, 1.577]} /> */}
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('models/gramophone.glb')
export default Model